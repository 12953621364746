import MuiCircularProgress from '@mui/material/CircularProgress';
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import { forwardRef } from 'react';
import { LinkBehavior, LinkBehaviorProps } from 'client/elements/LinkBehavior';
import styles from './IconButton.module.css';
interface Props extends Omit<MuiIconButtonProps, 'component'> {
  component?: 'span' | 'a';
  loading?: boolean;
}
const IconButton = forwardRef<HTMLButtonElement, Props>(function IconButton({
  className,
  disabled,
  loading,
  children,
  ...props
}, _ref) {
  return <MuiIconButton {...props} className={className} disabled={disabled || loading}>
      {children}
      {loading && <MuiCircularProgress className={styles.circularProgress} size={28} />}
    </MuiIconButton>;
});
export default IconButton;
export const IconButtonAsLink = (props: MuiIconButtonProps & LinkBehaviorProps) => <MuiIconButton {...props} component={LinkBehavior} data-sentry-element="MuiIconButton" data-sentry-component="IconButtonAsLink" data-sentry-source-file="IconButton.tsx" />;